import './App.css';
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import HomeView from './views/homeView';
import GameView from './views/gameView';

import { io } from 'socket.io-client';
import { config } from './shared/config';

import { SOUNDS, useAudio } from './views/gameView/Game/hooks/useAudio';

import { useEffect } from 'react';

function App() {
  const socket = io.connect(config.apiUrl, { 
    path: '/api/sockets',
    transports: ['websocket']
  });

  const backgroundMusic = useAudio(SOUNDS.BACKGROUND, {loop: true})

  useEffect(() => {
    backgroundMusic.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' >
            <HomeView socket={socket}/>
          </Route>
          <Route path='/game/:code'>
            <GameView socket={socket}/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
