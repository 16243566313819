import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import AudioSettings from '../../shared/AudioSettings';
import Background from '../../shared/Background';
import { audioService } from '../gameView/Game/hooks/audioService.service';
import HostRoomCard from './HostRoomCard';
import JoinRoomCard from './JoinRoomCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  logo: {
    marginBottom: '4rem',
    marginTop: '4rem',
    display: 'flex',
    alignItems: 'center'
  },
  audioSettings: {
    position: 'fixed',
    zIndex: 1024,
    top: 0,
    right: '32px',
    display: 'flex',
    flexDirection: 'column',
  }
}));

interface HomeProps {
  socket: Socket
}

const HomeView: FC<HomeProps> = ({ socket }) => {
  const classes = useStyles();

  useEffect(() => {
    audioService.init();
  }, [])

  return (
    <div className={classes.root}>
      <Background color='orange' />
      <Box className={classes.content}>
        <Box className={classes.audioSettings}>
          <AudioSettings></AudioSettings>
        </Box>
        <Box className={classes.logo}>
          <Box>
            <img
              src="/static/images/sescam_zampa_avanza.png"
              alt="Logo"
              style={{ width: '82px', height: '89px' }} // Adjust the width and height as needed
            />
          </Box>
          <Box style={{marginLeft: "2rem"}}>
            <img src="/static/images/logo.png" alt="Logo" />
          </Box>
        </Box>
        <JoinRoomCard socket={socket} />
        <HostRoomCard socket={socket} />
      </Box>
    </div>
  )
}

export default HomeView;
